import { updateCart, updateCartBundle } from "../../react/api";
import { ToastStore } from "../../react/stores/ToastStore";
import htmx from "htmx.org";

export function initMsAddToCartBtn() {

	// set product image async. We show the loading state till image loading is complete

	// since we know htmx is loaded and executed before the rest by sprig we can safely call here htmx
	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function (event) {

		const target = event.target;
		let count = 1;
		//console.log(target.id);

		//Add product to cart
		let productAddToCartBtns = document.querySelectorAll("#"+target.id+" .ms-add-to-cart-btn");

		if (productAddToCartBtns.length) {
			productAddToCartBtns.forEach(function (msBtn) {

				const productVariantId = msBtn.dataset.productVariantId;
				let isAddToCartLoading = false;
				const btnText = msBtn.querySelector(".btn-inner");

				msBtn.addEventListener("click", function() {

					if (!productVariantId || isAddToCartLoading) {
						return;
					}

					isAddToCartLoading = true;
					msBtn.classList.add("loading");

					const data = {
						amount: count,
					};

					updateCart(productVariantId, count).then(resp => {
						if(resp.cart) {
							const cart = resp.cart;
							if(cart.lineItems) {
								data.lineItems = cart.lineItems.reduce(
									(prevVal, currentValue) =>
										prevVal + currentValue.qty,
									0
								);
							}
						}
					}).finally(() => {
						isAddToCartLoading = false;
						msBtn.classList.remove("loading");
						msBtn.classList.add("disabled");
						msBtn.disabled = true;
						btnText.textContent = "Bereits hinzugefügt";
						ToastStore.addMessestandProductToCart(data);
					});
				})
			});
		}

		//Add bundle products to cart
		let msProductBundles = document.querySelector("#"+target.id+" .ms-product-bundle");

		if (msProductBundles) {

			let addToCartBtn = document.querySelector("#"+target.id+" .ms-bundle-add-to-cart-btn");
			let isAddToCartLoading = false;

			if (addToCartBtn) {

				const btnText = addToCartBtn.querySelector(".btn-inner");

				addToCartBtn.addEventListener("click", function() {

					if (isAddToCartLoading) return;

					isAddToCartLoading = true;
					addToCartBtn.classList.add("loading");

					const purchasables = [];
					let productsInBundle = document.querySelectorAll("#"+target.id+" .ms-product-bundle .product .purchasable-id-for-ajax");

					productsInBundle.forEach(function (msProductVariableId) {
						purchasables.push(msProductVariableId.value);
					});

					const data = {
						amount: count,
					};

					updateCartBundle(purchasables).then(resp => {
						if(resp.cart) {
							const cart = resp.cart;
							if(cart.lineItems) {
								data.lineItems = cart.lineItems.reduce(
									(prevVal, currentValue) =>
										prevVal + currentValue.qty,
									0
								);
							}
						}
					}).finally(() => {
						isAddToCartLoading = false;
						addToCartBtn.classList.remove("loading");
						addToCartBtn.classList.add("disabled");
						addToCartBtn.disabled = true;
						btnText.textContent = "Bereits hinzugefügt";
						ToastStore.addMessestandProductToCart(data);
					});

				});
			}
		}

	});
}