import "intersection-observer";
import "./autoload";
import "./fslightbox"; // fslightbox pro version
import swiper from "./swiper";
import lazyload from "./dal-lazyload";
import "lazysizes";
import { initAccordions } from "./components/accordion";
import { initMainMenu } from "./components/main-menu";
import { initProductHero } from "./components/product-hero";
import { initMsAddToCartBtn } from "./components/ms-product";
import { initEventCard } from "./components/event-card";
import { initProductTabSlider } from "./components/product-tab-slider";
import { initPersonalizedSwiper } from "./components/personalized-swiper";
import { initEventSwiper } from "./components/event-swiper";
import { initTeachingSeriesPagination } from "./components/teaching-series-pagination";
import { initOpenSearchOverlay } from "./components/open-search-overlay";
import { initDropdown } from "./components/dropdown";
import { initDigitalAssetsPagination } from "./components/digital-assets-pagination";
import { initEventsPagination } from "./components/events-pagination";
import { initTeachingSeriesHero } from "./components/teaching-series-hero";
import { initMagazineArticleShare } from "./components/magazine-article-share";
import { initFaqAccordions } from "./components/faqs-accordion";
import { initSupervisorFilter } from "./components/supervisor-filter";
import { initBodyClickEvent } from "./components/body-click-event";
import { initForms } from "./components/forms";
import { initMatrixTabMenu } from "./components/matrix-tab-menu";
import { initMagazineArticleBookmark } from "./helper/magazine-article-bookmark";
import { initMagazinePagination } from "./components/magazine-pagination";
import { initStickyFilterButtons } from "./components/sticky-filter-buttons";
import { initStickyFilterButtonsDetailPage } from "./components/sticky-filter-buttons-detailpage";
import cookieBanner from "./components/cookie-banner";
import initGlobalTracking from "./helper/tracking";
import { initGuestUserSwipers } from "./components/guest-user-swipers";
import "./htmx-declare";

export default {

	init() {
		lazyload.init();

		//TODO: load dynamicly accordion dynamically
		const accordionElements = document.querySelector(".accordion");
		if (accordionElements) {
			initAccordions();
		}

		const mainMenu = document.querySelector(".main-menu");
		if (mainMenu) {
			initMainMenu();
		}

		const productHeroElements = document.querySelector(".product-hero");
		if (productHeroElements) {
			initProductHero();
		}

		const messestandCategoryPage = document.querySelector(".virtuellen-messestand-sub");
		if (messestandCategoryPage) {
			initMsAddToCartBtn();
		}

		const eventsPagination = document.getElementById("events-pagination-component");

		if (eventsPagination) {
			initEventsPagination();
		}

		//Init all swiper if there are some
		let allSwiper = document.querySelectorAll(".swiper-container:not(.deferred-swiper)");
		if (allSwiper.length) {
			allSwiper.forEach(function (swiperEle) {
				//Set swiper element and swiper options from data-attribute
				swiper.init(swiperEle, JSON.parse(swiperEle.dataset.swiperOptions));
			})
		}

		//Init productTabSlider
		if (document.querySelector(".product-tab-slider")) {
			initProductTabSlider();
		}

		//Init teachingSeriesPagination
		if (document.getElementById("sprig-teaching-series")) {
			initTeachingSeriesPagination();
		}

		//Init magazinePagination
		if (document.getElementById("magazine-pagination-component")) {
			initMagazinePagination();
		}

		if (document.querySelector(".js-search-trigger-open")) {
			initOpenSearchOverlay();
		}

		// if (document.querySelector(".js-related-products-items .collapsed-area")) {
		// 	initRelatedProductsCollapse();
		// }

		const dropdown = document.querySelector(".custom-dropdown");
		if (dropdown) {
			initDropdown();
		}

		if (document.getElementById("sprig-digital-assets")) {
			initDigitalAssetsPagination();
		}

		if (document.querySelector(".section-event-hero")) {
			initEventCard();
		}

		if (document.querySelector(".series-hero")) {
			initTeachingSeriesHero();
		}

		if (document.querySelector(".js-magazine-share")) {
			initMagazineArticleShare();
		}

		// Init personalized Swipers
		const personalizedSwiper = document.querySelector(".sprig-personalized-swiper");
		if (personalizedSwiper) {
			initPersonalizedSwiper();
		}

		const allGuestUserSwipers = document.querySelectorAll(".guest-user-swiper .personalized-swiper, .guest-user-swiper .event-swiper");
		if (allGuestUserSwipers.length) {
			initGuestUserSwipers(allGuestUserSwipers);
		}

		// Init event Swipers
		const eventSwiper = document.querySelector(".sprig-event-swiper");
		if (eventSwiper) {
			initEventSwiper();
		}

		//Init faqs
		const faqAccordionElements = document.querySelector(".section-faqs");
		if (faqAccordionElements) {
			initFaqAccordions();
		}

		//Init supervisor filter
		const supervisorFilter = document.querySelector(".sprig-supervisor-filter");
		if (supervisorFilter) {
			initSupervisorFilter();
		}

		//Init Forms
		const sectionForm = document.querySelector(".section-form");
		if (sectionForm) {
			initForms();
		}

		//Init Matrix Tab Menu
		const matrixTabMenu = document.querySelector(".matrix-tab-menu-bar");
		if(matrixTabMenu) {
			initMatrixTabMenu();
		}

		//Init sticky filter buttons
		const stickyFilterButtons = document.querySelector(".sticky-filter-buttons");
		if (stickyFilterButtons) {
			initStickyFilterButtons();
		}

		//Init sticky filter buttons on magazine detailpage
		const stickyFilterButtonsMagazineDetail = document.querySelector(".sticky-filter-buttons-magazine");
		if (stickyFilterButtonsMagazineDetail) {
			initStickyFilterButtonsDetailPage();
		}

		//Init click on body whenever needed
		if (dropdown || mainMenu) {
			initBodyClickEvent();
		}

		initMagazineArticleBookmark();

		//Init global tracking
		initGlobalTracking();

		const maintenanceMode = document.querySelector("body.maintenance");

		if (!maintenanceMode) {
			// Init Cookie Banner Settings
			cookieBanner.init();
		}

	},
}
