import { observable } from "mobx";

export interface IToastLink {
	text: string;
	url: string;
	target?: "self" | "blank";
}

export type State =
	| "success"
	| "error"
	| "info"
	| "addBookmark"
	| "removeBookmark"
	| "addToCart"
	| "addMsProductToCart"
	| "removeFromCart";

export interface IToastAction {
	action: () => void;
	text: string;
}

export interface IAddToCartData {
	title: string;
	imageUrl: string;
	productType: string;
	amount: number;
	price: number;
	priceAsCurrency: string;
	lineItems: number;

}

export interface IToastMessage {
	message?: string | JSX.Element;
	duration: number;
	state: State;
	id: number;
	link?: IToastLink | null;
	imageUrl?: string;
	productId?: string;
	action?: IToastAction;
	type?: ElementType;
	title?: string;
	productType?: string;
	amount?: number;
	price?: number;
	priceAsCurrency?: string;
	lineItems?: number;
}

export type ElementType = "product" | "event" | "article";
export interface IToastStore {
	messages: {
		[key: number]: IToastMessage;
	};
	timeoutId: number | null;
	isVisible: boolean;
	removeMessage: (id: number) => void;
	addMessage: (
		state: State,
		message: string | JSX.Element,
		link?: IToastLink | null,
		duration?: number
	) => void;
	success: (
		message: string | JSX.Element,
		link?: IToastLink | null,
		duration?: number
	) => void;
	info: (
		message: string | JSX.Element,
		link?: IToastLink | null,
		duration?: number
	) => void;
	error: (
		message: string | JSX.Element,
		link?: IToastLink | null,
		duration?: number
	) => void;
	addToBookmark: (
		imageUrl: string,
		productUrl: string,
		fallbackImage?: string,
		type?: ElementType
	) => void;
	removeFromBookmark: (
		imageUrl: string,
		productUrl: string,
		fallbackImage?: string,
		type?: ElementType
	) => void;
	addToCart: (data: IAddToCartData) => void;
	addMessestandProductToCart: (data: IAddToCartData) => void;
	removeFromCart: (imageUrl: string, productId: string) => void;
}

const toastStore: IToastStore = observable({
	messages: {},
	timeoutId: null,
	isVisible: false,
	addToCart: (data: IAddToCartData) => {
		const id = Date.now();

		const componentToRefresh = document.getElementById(
			"sprig-cart-item-count"
		);

		if (componentToRefresh) {
			componentToRefresh.dispatchEvent(new Event("refresh"));
		}

		toastStore.messages[id] = {
			id,
			imageUrl: data.imageUrl,
			title: data.title,
			productType: data.productType,
			amount: data.amount,
			price: data.price,
			priceAsCurrency: data.priceAsCurrency,
			lineItems: data.lineItems,
			state: "addToCart",
			duration: 6000,
		};
	},
	addMessestandProductToCart: (data: IAddToCartData) => {
		const id = Date.now();
		const componentToRefresh = document.getElementById("sprig-cart-item-count");
		if (componentToRefresh) {
			componentToRefresh.dispatchEvent(new Event("refresh"));
		}
		toastStore.messages[id] = {
			id,
			amount: data.amount,
			lineItems: data.lineItems,
			state: "addMsProductToCart",
			duration: 6000,
		};
	},
	removeFromCart: (imageUrl: string, productId: string) => {
		const id = Date.now();
		toastStore.messages[id] = {
			id,
			imageUrl,
			productId,
			state: "removeBookmark",
			duration: 5000,
		};
	},
	addToBookmark: (
		imageUrl: string,
		linkUrl: string,
		fallbackImage?: string,
		elementType?: ElementType
	) => {
		const id = Date.now();
		toastStore.messages[id] = {
			id,
			imageUrl,
			state: "addBookmark",
			link: { text: "", url: linkUrl, target: "self" },
			duration: 5000,
			type: elementType,
		};
	},
	removeFromBookmark: (
		imageUrl: string,
		productUrl: string,
		fallbackImage?: string,
		elementType?: ElementType
	) => {
		const id = Date.now();
		toastStore.messages[id] = {
			id,
			imageUrl,
			link: { text: "", url: productUrl, target: "self" },
			state: "removeBookmark",
			duration: 5000,
			type: elementType,
		};
	},
	removeMessage: (id: number) => {
		if (toastStore.messages[id]) {
			delete toastStore.messages[id];
		}
	},
	addMessage: (
		state: State,
		message: string | JSX.Element,
		link?: IToastLink | null,
		duration = 5000
	) => {
		const id = Date.now();
		toastStore.messages[id] = {
			message,
			duration,
			id,
			link,
			state,
		};
	},
	success: (
		message: string | JSX.Element,
		link?: IToastLink | null,
		duration = 5000
	) => {
		toastStore.addMessage("success", message, link, duration);
	},
	error: (
		message: string | JSX.Element,
		link?: IToastLink | null,
		duration = 5000
	) => {
		toastStore.addMessage("error", message, link, duration);
	},
	info: (
		message: string | JSX.Element,
		link?: IToastLink | null,
		duration = 5000
	) => {
		toastStore.addMessage("info", message, link, duration);
	},
	progress: (
		message: string | JSX.Element,
		link?: IToastLink | null,
	) => {
		toastStore.addMessage("info", message, link, undefined);
	},
});

export const ToastStore = toastStore;
